import 'core-js/stable'
import 'regenerator-runtime/runtime'

import initGlobal, { initCancelPageHeader } from '@bikemap/js/integrations/global'
import { showLoginMethodModal, initLoginFormById, initPasswordForm } from '@bikemap/js/integrations/authentication'
import { defaultPost, handleRedirect } from '@bikemap/js/utility'
import replaceCounters from '@bikemap/js/integrations/counters'
import { LANGUAGE } from '@bikemap/js/settings'

initGlobal()
initCancelPageHeader()

// Initialize the email login form if present
initLoginFormById('login')

// Initialize the login buttons when there are multiple accounts
const loginForms = document.querySelectorAll('.select-account-form')
for (let i = 0; i < loginForms.length; i++) { // IE
    const form = /** @type {HTMLFormElement} */ (loginForms[i])

    form.addEventListener('submit', e => {
        e.preventDefault()

        defaultPost(form.action, new FormData(form))
            .then(handleRedirect)
    })
}

// Initialize buttons that trigger the modal to select auth method
const chooseLoginBtns = document.querySelectorAll('.choose-login-button')
for (let i = 0; i < chooseLoginBtns.length; i++) { // IE
    const btn = /** @type {HTMLButtonElement} */ (chooseLoginBtns[i])

    btn.addEventListener('click', e => {
        e.preventDefault()
        showLoginMethodModal(/** @type {{ profile, facebook, google, apple, username, action }} */ (btn.dataset))
    })
}

// Initialize the password form
const passwordForm = document.getElementById('password-login')
if (passwordForm) initPasswordForm(/** @type {HTMLFormElement} */ (passwordForm))

// Language switch
const languageLink = /** @type {HTMLAnchorElement} */ (document.querySelector('.choose-language'))
if (languageLink) {
    languageLink.addEventListener('click', e => {
        e.preventDefault()
        const locale = languageLink.dataset.language
        location.href = location.href.replace('/' + LANGUAGE + '/', '/' + locale + '/')
    })
}

replaceCounters()
